import React from 'react';
import { Box } from 'grommet'

const ContactSuccess = () => {
  return <Box
      direction="row"
      align="center"
      margin="0px"
      flex="grow"
      className="indexContainer"
      animation={["fadeIn", "slideUp"]}
    >
      <div className="indexHero pageHero">
        <h1>Thank you for your message!</h1>
        <p>
            If needed, I will respond shortly.
        </p>
      </div>
    </Box>
};

export default ContactSuccess;
